import axios from 'axios'

export const apiURL =
  process.env.NEXT_PUBLIC_BACKEND_URL || 'http://localhost:3040'

const client = axios.create({
  baseURL: apiURL,
})

export default client
