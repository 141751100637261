/* eslint-disable no-param-reassign */
import { RestaurantsGridItem } from '@/graphql/fragments/restaurantGridItem'
import { RestaurantDTO, CuisineDTO } from '@/types/dto'
import { FoodType, SortingKey } from '@/types/filters'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type CuisineDictionary = Partial<Record<FoodType, true>>

export interface BrowseState {
  selectedRestaurant: RestaurantDTO | null
  hoveredRestaurantId: string | null
  cuisines: CuisineDTO[]
  selectedCuisines: CuisineDictionary
  search: string
  dishesSearch: string
  tags: string[]
  restaurantsSort: SortingKey
  dishesSort: SortingKey
  lunchesSort: SortingKey
  isFiltersModalVisible: boolean
  restaurantsOnMapIds: string[]
}

const initialState: BrowseState = {
  selectedRestaurant: null,
  hoveredRestaurantId: null,
  cuisines: [],
  selectedCuisines: {},
  search: '',
  dishesSearch: '',
  tags: [],
  restaurantsSort: undefined,
  dishesSort: undefined,
  lunchesSort: undefined,
  isFiltersModalVisible: false,
  restaurantsOnMapIds: [],
}

export const browseSlice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    setSelectedRestaurant: (
      state,
      action: PayloadAction<RestaurantDTO | null>,
    ) => {
      state.selectedRestaurant = action.payload
    },
    setCuisines: (state, action: PayloadAction<CuisineDTO[]>) => {
      state.cuisines = action.payload
    },
    addSelectedCuisine: (state, action: PayloadAction<FoodType>) => {
      state.selectedCuisines[action.payload] = true

      // potentialy not needed
      if (
        action.payload !== undefined &&
        !state.selectedRestaurant?.cuisines?.some(
          (cuisine) => state.selectedCuisines[cuisine.name],
        )
      ) {
        state.selectedRestaurant = null
      }
    },
    removeSelectedCuisine: (state, action: PayloadAction<FoodType>) => {
      delete state.selectedCuisines[action.payload]

      // not sure if this still needed
      if (
        action.payload !== undefined &&
        !state.selectedRestaurant?.cuisines?.some(
          (cuisine) => state.selectedCuisines[cuisine.name],
        )
      ) {
        state.selectedRestaurant = null
      }
    },
    setSelectedCuisines: (state, action: PayloadAction<CuisineDictionary>) => {
      state.selectedCuisines = action.payload

      // consider removing
      if (
        action.payload !== undefined &&
        !state.selectedRestaurant?.cuisines?.some(
          (cuisine) => state.selectedCuisines[cuisine.name],
        )
      ) {
        state.selectedRestaurant = null
      }
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setRestaurantsSort: (state, action: PayloadAction<SortingKey>) => {
      state.restaurantsSort = action.payload
    },
    setDishesSearch: (state, action: PayloadAction<string>) => {
      state.dishesSearch = action.payload
    },
    setTags: (state, action: PayloadAction<string | null>) => {
      if (!action.payload) {
        state.tags = []
      } else {
        if (state.tags.some((tag) => tag === action.payload)) {
          state.tags = state.tags.filter((tag) => tag !== action.payload)
        } else {
          state.tags = [...state.tags, action.payload]
        }
      }
    },
    setDishesSort: (state, action: PayloadAction<SortingKey>) => {
      state.dishesSort = action.payload
    },
    setLunchesSort: (state, action: PayloadAction<SortingKey>) => {
      state.lunchesSort = action.payload
    },
    setIsFiltersModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isFiltersModalVisible = action.payload
    },
    setHoveredRestaurantId: (state, action: PayloadAction<string>) => {
      state.hoveredRestaurantId = action.payload
    },
    unsetHoveredRestaurantId: (state, action: PayloadAction<string>) => {
      // this check is to prevent race conditions
      if (state.hoveredRestaurantId === action.payload) {
        state.hoveredRestaurantId = null
      }
    },
    setRestaurantsOnMapIds: (state, action: PayloadAction<string[]>) => {
      state.restaurantsOnMapIds = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setSelectedCuisines,
  addSelectedCuisine,
  removeSelectedCuisine,
  setSelectedRestaurant,
  setCuisines,
  setSearch,
  setRestaurantsSort,
  setDishesSearch,
  setTags,
  setDishesSort,
  setLunchesSort,
  setIsFiltersModalVisible,
  setHoveredRestaurantId,
  unsetHoveredRestaurantId,
  setRestaurantsOnMapIds,
} = browseSlice.actions

export default browseSlice.reducer
