/* eslint-disable no-param-reassign */
import { Badge, Feature } from '@/types/dto'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type FiltersType =
  | 'minRating'
  | 'priceRanges'
  | 'otherFilters'
  | 'delivery'

export type GridThumbnail = 'primary' | 'interior' | 'garden'

export type OtherRestaurantFilter = 'open' | 'kitchen'

export interface RestaurantFiltersState {
  minRating: 0 | 1 | 2 | 3 | 4 | 5
  priceRanges: (1 | 2 | 3 | 4 | 5)[]
  features: Feature[]
  badges: Badge[]
  otherFilters: OtherRestaurantFilter[]
  delivery: string[]
  gridThumbnail: GridThumbnail
}

export interface DishFiltersState {
  minRating: undefined
}

export interface FiltersState {
  restaurantFilters: RestaurantFiltersState
  dishFilters: DishFiltersState
}

const initialState: FiltersState = {
  restaurantFilters: {
    minRating: 0,
    priceRanges: [],
    features: [],
    badges: [],
    otherFilters: [],
    delivery: [],
    gridThumbnail: 'primary',
  },
  dishFilters: {
    minRating: undefined,
  },
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setRestaurantFilters: (
      state,
      action: PayloadAction<RestaurantFiltersState>,
    ) => {
      state.restaurantFilters = action.payload
    },
    resetRestaurantFilters: (state) => {
      state.restaurantFilters = {
        ...state.restaurantFilters,
        minRating: 0,
        priceRanges: [],
        otherFilters: [],
        delivery: [],
        features: [],
        badges: [],
      }
    },
    resetRestaurantMinRating: (state) => {
      state.restaurantFilters.minRating = 0
    },
    removeRestaurantPriceRange: (state) => {
      state.restaurantFilters.priceRanges = []
    },
    removeRestaurantDelivery: (state, action: PayloadAction<string>) => {
      state.restaurantFilters.delivery = state.restaurantFilters.delivery.filter(
        (value) => value !== action.payload,
      )
    },
    removeRestaurantFeature: (state, action: PayloadAction<string>) => {
      state.restaurantFilters.features = state.restaurantFilters.features.filter(
        (value) => value !== action.payload,
      )
    },
    removeRestaurantBadge: (state, action: PayloadAction<string>) => {
      state.restaurantFilters.badges = state.restaurantFilters.badges.filter(
        (value) => value !== action.payload,
      )
    },
    removeRestaurantOther: (state, action: PayloadAction<string>) => {
      state.restaurantFilters.otherFilters = state.restaurantFilters.otherFilters.filter(
        (value) => value !== action.payload,
      )
    },
    removeIntegrated: (state, action: PayloadAction<string>) => {
      state.restaurantFilters.otherFilters = state.restaurantFilters.otherFilters.filter(
        (filter) => !action.payload.includes(filter),
      )

      state.restaurantFilters.features = state.restaurantFilters.features.filter(
        (filter) => !action.payload.includes(filter),
      )

      state.restaurantFilters.badges = state.restaurantFilters.badges.filter(
        (filter) => !action.payload.includes(filter),
      )

      state.restaurantFilters.delivery = state.restaurantFilters.delivery.filter(
        (filter) => !action.payload.includes(filter),
      )

      if (action.payload.includes('gridThumbnail')) {
        state.restaurantFilters.gridThumbnail = 'primary'
      }

      // these 2 below won't be executed now, but may be in the future when filters change
      if (action.payload.includes('minRating')) {
        state.restaurantFilters.minRating = 0
      }

      if (action.payload.includes('priceRanges')) {
        state.restaurantFilters.priceRanges = []
      }
    },
    setRestaurantGridThumbnail: (
      state,
      action: PayloadAction<GridThumbnail>,
    ) => {
      state.restaurantFilters.gridThumbnail = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setRestaurantFilters,
  resetRestaurantFilters,
  resetRestaurantMinRating,
  removeRestaurantPriceRange,
  removeRestaurantDelivery,
  removeRestaurantFeature,
  removeRestaurantBadge,
  removeRestaurantOther,
  removeIntegrated,
  setRestaurantGridThumbnail,
} = filtersSlice.actions

export default filtersSlice.reducer
