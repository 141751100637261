import '../utils/wdyr'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { BaseModalBackground, ModalProvider } from 'styled-react-modal'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import { getApolloClient } from '@/clients/apollo'
import * as gtag from '@/utils/gtag'

import { store } from '../redux/store'

import '@/styles/global.css'
import { useEffect } from 'react'
import { IS_PRODUCTION } from '@/config'

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props: { opacity: number }) => props.opacity};
  transition: all 0.1s ease-in-out;
`

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()

  // @ts-expect-error TODO figure out this type
  const getLayout = Component.getLayout || ((page: any) => page)

  const client = getApolloClient()

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      /* invoke analytics function only for production */
      if (IS_PRODUCTION) gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ModalProvider backgroundComponent={FadingBackground}>
          {getLayout(<Component {...pageProps} />)}
        </ModalProvider>
      </Provider>
    </ApolloProvider>
  )
}

export default MyApp
