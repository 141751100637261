import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { apiURL } from './axios'

export const isServer = typeof window === 'undefined'
// @ts-expect-error this will be present on the object
// eslint-disable-next-line no-underscore-dangle
const windowApolloState = !isServer && window.__NEXT_DATA__?.apolloState

let CLIENT: ApolloClient<NormalizedCacheObject> | null = null

export function getApolloClient(forceNew?: boolean) {
  if (!CLIENT || forceNew) {
    CLIENT = new ApolloClient({
      ssrMode: isServer,
      uri: `${apiURL}/graphql`,
      cache: new InMemoryCache().restore(windowApolloState || {}),

      /**
        // Default options to disable SSR for all queries.
        defaultOptions: {
          // Skip queries when server side rendering
          // https://www.apollographql.com/docs/react/data/queries/#ssr
          watchQuery: {
            ssr: false
          },
          query: {
            ssr: false
          }
          // Selectively enable specific queries like so:
          // `useQuery(QUERY, { ssr: true });`
        }
      */
    })
  }

  return CLIENT
}
