// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const isSentryEnabled =
  (process.env.SENTRY_ENABLED || process.env.NEXT_PUBLIC_SENTRY_ENABLED) ===
  'true'

if (isSentryEnabled) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://f4624cae6d2b44aaaa7b0bf73f0b8d13@o1104892.ingest.sentry.io/6132301',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
