/* eslint-disable no-param-reassign */
import { Coordinates } from '@/types/coordinates'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface MapState {
  center: Coordinates
  userPosition: Coordinates | null
  zoom: [number]
  radius: number | null
  selectedLocation: string
}

const initialState: MapState = {
  center: [21.00876708752258, 52.235270427801986],
  userPosition: null,
  zoom: [13.5],
  radius: null,
  selectedLocation: 'Warszawa',
}

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setCenter: (state, action: PayloadAction<Coordinates>) => {
      state.center = action.payload
    },
    setUserPosition: (state, action: PayloadAction<Coordinates | null>) => {
      state.userPosition = action.payload
    },
    setZoom: (state, action: PayloadAction<[number]>) => {
      state.zoom = action.payload
    },
    setRadius: (state, action: PayloadAction<number>) => {
      state.radius = action.payload
    },
    setSelectedLocation: (state, action: PayloadAction<string>) => {
      state.selectedLocation = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setCenter,
  setUserPosition,
  setZoom,
  setRadius,
  setSelectedLocation,
} = mapSlice.actions

export default mapSlice.reducer
