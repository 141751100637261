import { configureStore } from '@reduxjs/toolkit'

import mapReducer from './slices/mapSlice'
import exploreReducer from './slices/exploreSlice'
import filtersReducer from './slices/filtersSlice'
import restaurantPageReducer from './slices/restaurantPageSlice'
import { nestApi } from './services/nest'

export const store = configureStore({
  reducer: {
    map: mapReducer,
    explore: exploreReducer,
    filters: filtersReducer,
    restaurantsPage: restaurantPageReducer,
    [nestApi.reducerPath]: nestApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(nestApi.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
