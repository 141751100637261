/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RestaurantPageState {
  isDeliveryDropdownVisible: boolean
}

const initialState: RestaurantPageState = {
  isDeliveryDropdownVisible: false,
}

export const restaurantPageSlice = createSlice({
  name: 'explore',
  initialState,
  reducers: {},
})

// Action creators are generated for each case reducer function
// export const {  } = restaurantPageSlice.actions

export default restaurantPageSlice.reducer
